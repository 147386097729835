<template>
  <div>
    <v-menu
      ref="datePickerMenu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="value"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :id="id"
          v-model="dateText"
          autocomplete="off"
          :disabled="disabled"
          :placeholder="placeholder"
          :label="label"
          v-bind="attrs"
          class="datepicker-input"
          append-icon="mdi-calendar-outline"
          readonly
          dense
          outlined
          clearable
          v-on="on"
        >
        </v-text-field>
      </template>
      <v-date-picker v-model="date" locale="pt-br" no-title scrollable :type="type">
        <v-spacer></v-spacer>
        <v-btn text color="primary" class="btn-ag-secondary" @click="menu = false"> Cancelar </v-btn>
        <v-btn text class="btn-ag-primary" @click="$refs.datePickerMenu.save(value)"> Confirmar </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'AGDatePicker',

  props: {
    id: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      require: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'date',
    },

    placeholder: {
      type: String,
      required: false,
    },

    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      menu: false,
    };
  },

  computed: {
    dateText: {
      get: function () {
        let dateComputed = [];
        if (this.value.length > 0) {
          dateComputed = this.formatDate(this.value);
        }
        return dateComputed;
      },

      set: function (value) {
        if ([null, undefined].includes(value)) {
          value = '';
        }
        this.$emit('input', value);
      },
    },

    date: {
      get: function () {
        return this.value;
      },

      set: function (value) {
        this.$emit('change', value);
        this.$emit('input', value);
      },
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      if (this.type === 'month') {
        return `${month}/${year}`;
      }
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './src/design/components/datepicker.scss';
</style>
